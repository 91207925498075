 <template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="true" />

      <b-tabs>
        <template v-for="(vlang,klang) in lang">
          <b-tab :title="`Page Settings - ${vlang.text}`" :key="klang">
            <bo-card title="Hero Image" footer-tag="footer">
              <b-row class="gutter-2">
                <b-col md="3">
                  <bo-card-img
                    :src="uploader(((content['hero_' + vlang.value] || {}).value || {}).img)"
                    :title="((content['hero_' + vlang.value] || {}).value || {}).title"
                    :isTitleHtml="true"
                  >
                    <template #buttons>
                      <b-button
                        v-if="moduleRole('Edit')"
                        variant="secondary"
                        size="sm"
                        pill
                        @click="editHeroImage(content['hero_' + vlang.value])"
                        v-b-tooltip.hover="'Edit'"
                      >
                        <i class="fas fa-pencil-alt" />
                      </b-button>
                    </template>
                  </bo-card-img>
                </b-col>
              </b-row>
            </bo-card>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Introduction Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`introduction_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormIntroduction${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'introduction-section-setting',
                              content['introduction_' + vlang.value],
                              `VFormIntroduction${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12">
                                <BoField 
                                  label="Title"
                                  placeholder="e.g. Your journey to Jakarta Running Festival (JRF) starts with collecting your race pack!"
                                  v-model="((content['introduction_' + vlang.value] || {}).value || {}).title"
                                  :rules="(mrValidation.introduction_setting || {}).title"
                                  :editFormId="`introduction_section_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </div>
                              <div class="col-12">
                                <BoTextarea
                                  label="Description"
                                  placeholder="e.g. Dive into the excitement of race day by ensuring you collect your pack on time"
                                  v-model="((content['introduction_' + vlang.value] || {}).value || {}).description"
                                  :rules="(mrValidation.introduction_setting || {}).description"
                                  :editFormId="`introduction_section_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `introduction_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Schedule Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`schedule_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormSchedule${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'schedule-section-setting',
                              content['schedule_' + vlang.value],
                              `VFormSchedule${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-3">
                            <BoField 
                              label="Title"
                              placeholder="e.g. Schedule"
                              v-model="((content['schedule_' + vlang.value] || {}).value || {}).title"
                              :rules="(mrValidation.schedule_setting || {}).title"
                              :editFormId="`schedule_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </div>
                          <div class="col-md-9">
                            <label for="">Schedule Data</label>
                            <div class="row">
                              <template v-for="(v,k) in ((content['schedule_' + vlang.value] || {}).value || {}).data">
                                <div class="col-4" :key="k">
                                  <div class="border rounded mb-3 px-4 py-3">
                                    <template v-if="editFormId != `schedule_section_${vlang.value}`">
                                      <h5 class="fw-600 mb-0">{{ v['title'] }}</h5>
                                      <span>{{ v['description'] }}</span>
                                      <h5 class="mt-4 text-primary">{{ v['value'] }}</h5>
                                    </template>
                                    <template v-else>
                                      <div class="button-el d-flex justify-content-between">
                                        <span># {{ k + 1}}</span>
                                        <b-button
                                          v-if="moduleRole('Edit')"
                                          variant="danger"
                                          size="sm"
                                          pill
                                          v-b-tooltip.hover="'Delete'"
                                          @click="deleteItemData(((content['schedule_' + vlang.value] || {}).value || {}).data, k)"
                                        >
                                          <i class="fas fa-trash-alt" />
                                        </b-button>
                                      </div>
                                      <div>
                                        <label for="">Title <span class="text-danger">*</span></label>
                                        <b-form-input
                                          :id="`title${k}${vlang.value}`"
                                          placeholder="e.g. Sunday"
                                          v-model="v['title']"
                                        ></b-form-input>
                                        <VValidate
                                          :vid="`title${k}${vlang.value}`"
                                          name="Title"
                                          v-model="v['title']"
                                          :rules="(mrValidation.schedule_data || {}).title"
                                        />
                                      </div>
                                      <div class="my-3">
                                        <label for="">Sub Title <span class="text-danger">*</span></label>
                                        <b-form-input
                                          :id="`sub_title${k}${vlang.value}`"
                                          placeholder="e.g. March 15, 2024"
                                          v-model="v['description']"
                                        ></b-form-input>
                                        <VValidate
                                          :vid="`sub_title${k}${vlang.value}`"
                                          name="Sub Title"
                                          v-model="v['description']"
                                          :rules="(mrValidation.schedule_data || {}).sub_title"
                                        />
                                      </div>
                                      <div class="my-3">
                                        <label for="">Highlight Text <span class="text-danger">*</span></label>
                                        <b-form-input
                                          :id="`description${k}${vlang.value}`"
                                          placeholder="e.g. 11.00 - 21.00 WIB"
                                          v-model="v['value']"
                                        ></b-form-input>
                                        <VValidate
                                          :vid="`description${k}${vlang.value}`"
                                          name="Highlight Text"
                                          v-model="v['value']"
                                          :rules="(mrValidation.schedule_data || {}).highlight_text"
                                        />
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </template>
                              <template v-if="editFormId == `schedule_section_${vlang.value}`">
                                <div class="col-4">
                                  <div class="border rounded mb-3 px-4 py-3 d-flex justify-content-center align-items-center">
                                    <div class="d-flex align-items-center text-decoration-underline">
                                      <a href="javascript:void(0)" @click="addNewSchedule(((content['schedule_' + vlang.value] || {}).value || {}).data)">
                                        <i class="fa fa-plus-circle"></i>
                                        <span class="mx-2">Add New</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `schedule_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Item Benefit Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`item_benefit_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormItemBenefit${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'item-benefit-section-setting',
                              content['item_benefit_' + vlang.value],
                              `VFormItemBenefit${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-3">
                            <BoField 
                              label="Title"
                              placeholder="e.g. Schedule"
                              v-model="((content['item_benefit_' + vlang.value] || {}).value || {}).title"
                              :rules="(mrValidation.item_benefit_setting || {}).title"
                              :editFormId="`item_benefit_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </div>
                          <div class="col-md-9">
                            <label for="">Benefit Data</label>
                            <div class="row">
                              <template v-for="(v,k) in ((content['item_benefit_' + vlang.value] || {}).value || {}).data">
                                <div class="col-4" :key="k">
                                  <div class="card-bg bg-img border rounded mb-3 px-3 py-3" :style="editFormId != `item_benefit_section_${vlang.value}` ? 'height: 250px;' : ''">
                                    <template v-if="editFormId != `item_benefit_section_${vlang.value}`">
                                      <img :src="uploader(v.fallback_img)" :alt="v.alt_img">
                                      <h5 class="fw-600 mb-0">{{ v['text'] }}</h5>
                                    </template>
                                    <template v-else>
                                      <div class="content-edit w-100">
                                        <div class="button-el d-flex justify-content-between">
                                          <span># {{ k + 1}}</span>
                                          <b-button
                                            v-if="moduleRole('Edit')"
                                            variant="danger"
                                            size="sm"
                                            pill
                                            v-b-tooltip.hover="'Delete'"
                                            @click="deleteItemData(((content['item_benefit_' + vlang.value] || {}).value || {}).data, k)"
                                          >
                                            <i class="fas fa-trash-alt" />
                                          </b-button>
                                        </div>
                                        <div>
                                          <label for="">Image <span class="text-danger">*</span></label>
                                          <Uploader
                                            :readonly="!moduleRole('Edit')"
                                            v-model="((content['item_benefit_' + vlang.value] || {}).value || {}).data[k].fallback_img"
                                            :param="{ thumbnail: true }"
                                            :squarePreview="true"
                                            type="item_benefit"
                                            label="Image"
                                            @data="v => ((content['item_benefit_' + vlang.value] || {}).value || {}).data[k].img = v"
                                          />
                                          <VValidate
                                            name="Image"
                                            v-model="((content['item_benefit_' + vlang.value] || {}).value || {}).data[k].img"
                                            :rules="mrValidation.item_benefit_setting.img"
                                          />
                                        </div>
                                        <div class="mt-3">
                                          <label for="">Alt Image <span class="text-danger">*</span></label>
                                          <b-form-input
                                            :id="`alt_img${k}${vlang.value}`"
                                            placeholder="e.g. BIB Number Picture"
                                            v-model="v['alt_img']"
                                          ></b-form-input>
                                          <VValidate
                                            :vid="`alt_img${k}${vlang.value}`"
                                            name="Alt Image"
                                            v-model="v['alt_img']"
                                            :rules="(mrValidation.item_benefit_data || {}).alt_img"
                                          />
                                        </div>
                                        <div class="mt-3">
                                          <label for="">Text <span class="text-danger">*</span></label>
                                          <b-form-input
                                            :id="`text${k}${vlang.value}`"
                                            placeholder="e.g. BIB Number"
                                            v-model="v['text']"
                                          ></b-form-input>
                                          <VValidate
                                            :vid="`text${k}${vlang.value}`"
                                            name="Text"
                                            v-model="v['text']"
                                            :rules="(mrValidation.item_benefit_data || {}).text"
                                          />
                                        </div>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </template>
                              <template v-if="editFormId == `item_benefit_section_${vlang.value}`">
                                <div class="col-4">
                                  <div class="border rounded mb-3 px-4 py-3 d-flex justify-content-center align-items-center">
                                    <div class="d-flex align-items-center text-decoration-underline">
                                      <a href="javascript:void(0)" @click="addNewItemBenefit(((content['item_benefit_' + vlang.value] || {}).value || {}).data)">
                                        <i class="fa fa-plus-circle"></i>
                                        <span class="mx-2">Add New</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `item_benefit_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Collection Requirement Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`collection_requirement_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormCollectionRequirement${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'collection-requirement-section-setting',
                              content['collection_requirement_' + vlang.value],
                              `VFormCollectionRequirement${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-3">
                            <BoField 
                              label="Title"
                              placeholder="e.g. Collection Requirement"
                              v-model="((content['collection_requirement_' + vlang.value] || {}).value || {}).title"
                              :rules="(mrValidation.collection_requirement_setting || {}).title"
                              :editFormId="`collection_requirement_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </div>
                          <div class="col-md-9">
                            <div class="row">
                              <div class="col-12">
                                <BoTextarea
                                  label="Description Section"
                                  placeholder="e.g. There are 3 options for picking up your race packet."
                                  v-model="((content['collection_requirement_' + vlang.value] || {}).value || {}).description"
                                  :rules="(mrValidation.collection_requirement_setting || {}).description"
                                  :editFormId="`collection_requirement_section_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </div>
                              <div class="col-12">
                                <BoTextarea
                                  label="Highlight Description"
                                  placeholder="e.g. A Photo ID and BIB number are needed to pick up your race packet."
                                  v-model="((content['collection_requirement_' + vlang.value] || {}).value || {}).highlight_description"
                                  :rules="(mrValidation.collection_requirement_setting || {}).highlight_description"
                                  :editFormId="`collection_requirement_section_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </div>
                            </div>
                            <label for="">Option Data</label>
                            <div class="row">
                              <template v-for="(v,k) in ((content['collection_requirement_' + vlang.value] || {}).value || {}).data">
                                <div class="col-12" :key="k">
                                  <div class="border rounded mb-3 px-4 py-3">
                                    <template v-if="editFormId != `collection_requirement_section_${vlang.value}`">
                                      <h5 class="fw-600 mb-0">{{ v['title'] }}</h5>
                                      <div class="mt-2" v-html="v['description']"></div>
                                    </template>
                                    <template v-else>
                                      <div class="button-el d-flex justify-content-between">
                                        <span># {{ k + 1}}</span>
                                        <b-button
                                          v-if="moduleRole('Edit')"
                                          variant="danger"
                                          size="sm"
                                          pill
                                          v-b-tooltip.hover="'Delete'"
                                          @click="deleteItemData(((content['collection_requirement_' + vlang.value] || {}).value || {}).data, k)"
                                        >
                                          <i class="fas fa-trash-alt" />
                                        </b-button>
                                      </div>
                                      <div>
                                        <label for="">Title <span class="text-danger">*</span></label>
                                        <b-form-input
                                          :id="`title${k}${vlang.value}`"
                                          placeholder="e.g. Option 1"
                                          v-model="v['title']"
                                        ></b-form-input>
                                        <VValidate
                                          :vid="`title${k}${vlang.value}`"
                                          name="Title"
                                          v-model="v['title']"
                                          :rules="(mrValidation.collection_requirement_data || {}).title"
                                        />
                                      </div>
                                      <div class="my-3">
                                        <label for="">Description <span class="text-danger">*</span></label>
                                        <template v-if="editFormId == `collection_requirement_section_${vlang.value}`">
                                          <ckeditor
                                            :editor="editor"
                                            v-model="v['description']"
                                            :config="editorConfig"
                                          ></ckeditor>
                                          <VValidate
                                            :vid="`description_option${vlang.value}`"
                                            name="Description"
                                            v-model="v['description']"
                                            rules="required|min:10"
                                          />
                                        </template>
                                        <template v-else>
                                          <div
                                            v-html="v['description']"
                                          ></div>
                                        </template>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </template>
                              <template v-if="editFormId == `collection_requirement_section_${vlang.value}`">
                                <div class="col-12">
                                  <div class="border rounded mb-3 px-4 py-3 d-flex justify-content-center align-items-center">
                                    <div class="d-flex align-items-center text-decoration-underline">
                                      <a href="javascript:void(0)" @click="addNewCollectionRequirement(((content['collection_requirement_' + vlang.value] || {}).value || {}).data)">
                                        <i class="fa fa-plus-circle"></i>
                                        <span class="mx-2">Add New</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `collection_requirement_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Location Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`location_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormLocation${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'location-section-setting',
                              content['location_' + vlang.value],
                              `VFormLocation${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-3">
                            <div class="row">
                              <div class="col-12">
                                <BoField 
                                  label="Title"
                                  placeholder="e.g. Location"
                                  v-model="((content['location_' + vlang.value] || {}).value || {}).title"
                                  :rules="(mrValidation.location_setting || {}).title"
                                  :editFormId="`location_section_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-9">
                            <div class="row">
                              <div class="col-12">
                                <BoTextarea
                                  label="Sub Title"
                                  placeholder="e.g. GBK Pintu Timur"
                                  v-model="((content['location_' + vlang.value] || {}).value || {}).title_value"
                                  :rules="(mrValidation.location_setting || {}).title_value"
                                  :editFormId="`location_section_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </div>
                              <div class="col-12">
                                <BoTextarea
                                  label="Description"
                                  placeholder="e.g. Komplek Gelora Bung Karno, Senayan, Jakarta Pusat"
                                  v-model="((content['location_' + vlang.value] || {}).value || {}).value"
                                  :rules="(mrValidation.location_setting || {}).value"
                                  :editFormId="`location_section_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </div>
                              <div class="col-12">
                                <BoField 
                                  label="URL Google Maps"
                                  placeholder="e.g. https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15865.36681492026!2d106.8026347!3d-6.2185912!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f16b11f44677%3A0xe0b6ad924afd76f0!2sKomplek%20Gelora%20Bung%20Karno!5e0!3m2!1sid!2sid!4v1710388051402!5m2!1sid!2sid"
                                  v-model="((content['location_' + vlang.value] || {}).value || {}).url_gmaps"
                                  :rules="(mrValidation.location_setting || {}).url_gmaps"
                                  :editFormId="`location_section_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </div>
                              <div class="col-12">
                                <template v-if="editFormId == `location_section_${vlang.value}`">
                                  <BoTextarea
                                    label="URL Embed Google Maps"
                                    placeholder="e.g. https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15865.36681492026!2d106.8026347!3d-6.2185912!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f16b11f44677%3A0xe0b6ad924afd76f0!2sKomplek%20Gelora%20Bung%20Karno!5e0!3m2!1sid!2sid!4v1710388051402!5m2!1sid!2sid"
                                    v-model="((content['location_' + vlang.value] || {}).value || {}).url_embed"
                                    :rules="(mrValidation.location_setting || {}).url_embed"
                                  />
                                </template>
                                <iframe class="rounded" :src="((content['location_' + vlang.value] || {}).value || {}).url_embed" width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `location_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Photo Collage</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`highlight_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormHighlight${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'highlight-section-setting',
                              content['highlight_' + vlang.value],
                              `VFormHighlight${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-6">
                            <b-form-group>
                              <label>
                                Image <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `highlight_section_${vlang.value}`">
                                <Uploader
                                  :readonly="!moduleRole('Edit')"
                                  v-model="((content['highlight_' + vlang.value] || {}).value || {}).fallback_img"
                                  :param="{ thumbnail: true }"
                                  :squarePreview="true"
                                  type="highlight_race_pack"
                                  label="Image"
                                  @data="v => ((content['highlight_' + vlang.value] || {}).value || {}).img = v"
                                />
                                <VValidate
                                  name="Image"
                                  v-model="((content['highlight_' + vlang.value] || {}).value || {}).img"
                                  :rules="mrValidation.highlight_setting.img"
                                />
                              </template>
                              <template v-else>
                                <img :src="uploader(((content['highlight_' + vlang.value] || {}).value || {}).img)" alt="" class="w-100">
                              </template>
                            </b-form-group>
                          </div>
                          <div class="col-6">
                            <b-form-group>
                              <label>
                                Small Image <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `highlight_section_${vlang.value}`">
                                <Uploader
                                  :readonly="!moduleRole('Edit')"
                                  v-model="((content['highlight_' + vlang.value] || {}).value || {}).fallback_img_small"
                                  :param="{ thumbnail: true }"
                                  :squarePreview="true"
                                  type="highlight_race_pack_small"
                                  label="Image"
                                  @data="v => ((content['highlight_' + vlang.value] || {}).value || {}).img_small = v"
                                />
                                <VValidate
                                  name="Image"
                                  v-model="((content['highlight_' + vlang.value] || {}).value || {}).img_small"
                                  :rules="mrValidation.highlight_setting.img_small"
                                />
                              </template>
                              <template v-else>
                                <img :src="uploader(((content['highlight_' + vlang.value] || {}).value || {}).img_small)" alt="" class="w-100">
                              </template>
                            </b-form-group>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `highlight_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div> -->

          </b-tab>
        </template>

        <template v-for="(vlangseo,klangseo) in lang">
          <b-tab :title="`SEO Settings - ${vlangseo.text}`" :key="`seo_${klangseo}`">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-10">
                    <h5 class="card-title">SEO Settings</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver 
                      v-slot="{ handleSubmit }" 
                      :ref="`VFormSeo${vlangseo.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'seo-setting',
                              content['seo_' + vlangseo.value],
                              `VFormSeo${vlangseo.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label :for="`seoTitle${vlangseo.value}`">
                                    Title
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-input
                                      :id="`seoTitle${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                    ></b-form-input>
                                    <VValidate
                                      :vid="`seoTitle${vlangseo.value}`"
                                      name="Title"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      rules="required|min:3|max:200"
                                    />
                                  </template>
                                  <template v-else>
                                    <p class="font-weight-bold">{{ ((content['seo_' + vlangseo.value] || {}).value || {}).title }}</p>
                                  </template>
                                </div>
                              </div>
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label>
                                    Description
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-textarea
                                      :id="`seoDescription${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                      rows="5"
                                    ></b-form-textarea>
                                    <VValidate
                                      :vid="`seoDescription${vlangseo.value}`"
                                      name="Description"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      rules="required|min:10|max:500"
                                    />
                                  </template>
                                  <template v-else>
                                    <div v-html="((content['seo_' + vlangseo.value] || {}).value || {}).description"></div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="moduleRole('Edit')">
                          <div class="col-12">
                            <hr />
                            <div class="text-right">
                              <template v-if="editFormId == `seo${vlangseo.value}`">
                                <button
                                  type="button"
                                  @click="editFormId = ''"
                                  class="btn btn-rounded btn-light mr-2"
                                >
                                  Cancel
                                </button>
                                <button type="submit" class="btn btn-rounded btn-primary">
                                  Save
                                </button>
                              </template>
                              <template v-else>
                                <button
                                  type="button"
                                  @click="editFormId = `seo${vlangseo.value}`"
                                  class="btn btn-rounded btn-primary"
                                >
                                  Ubah
                                </button>
                              </template>
                            </div>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </template>
      </b-tabs>

      <ValidationObserver v-slot="{ handleSubmit }" ref="VFormHeroImage">
        <b-modal
          id="heroImageModal"
          size="md"
          title="Edit Hero Image"
          @ok.prevent="
            handleSubmit(
              updateContent(
                'hero-image',
                inputHeroImage,
                'VFormHeroImage',
                'heroImageModal'
              )
            )
          "
        >
          <template #default>
            <div class="infoHTML"></div>
            <b-form 
              @submit.prevent="
                  handleSubmit(
                    updateContent(
                      'hero-image',
                      inputHeroImage,
                      'VFormHeroImage'
                    )
                  )
                "
            >
              <b-row>
                <b-col md="12">
                  <BoField 
                    label="Sub Title"
                    placeholder="e.g. RACE PACK COLLECTION"
                    v-model="((inputHeroImage || {}).value || {}).sub_title"
                    :rules="mrValidation.sub_title"
                  />
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Title"
                    placeholder="e.g. Jakarta Running Festival 2024"
                    v-model="((inputHeroImage || {}).value || {}).title"
                    :rules="mrValidation.title"
                  />
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="((inputHeroImage || {}).value || {}).fallback_img"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="hero_image"
                      label="Image"
                      @data="v => ((inputHeroImage || {}).value || {}).img = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="((inputHeroImage || {}).value || {}).img"
                      :rules="mrValidation.hero_image.img"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Small Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="((inputHeroImage || {}).value || {}).fallback_img_small"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="hero_small"
                      label="Image"
                      @data="v => ((inputHeroImage || {}).value || {}).img_small = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="((inputHeroImage || {}).value || {}).img_small"
                      :rules="mrValidation.hero_image.img_small"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Alt Image"
                    placeholder="e.g. Hero Image"
                    v-model="((inputHeroImage || {}).value || {}).alt_img"
                    :rules="mrValidation.alt_img"
                  />
                </b-col>
                <hr />
              </b-row>
            </b-form>
          </template>

          <template #modal-footer="{ ok, cancel }">
            <b-button variant="outline-secondary" @click="cancel()">
              Cancel
            </b-button>
            <b-button variant="info" @click="ok()">
              Submit
            </b-button>
          </template>
        </b-modal>
      </ValidationObserver>

    </div>
  </div>
</template>

<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";

import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@libs/ckeditor5-custom-build/build/ckeditor";
import { extend } from "vee-validate";
import assign from "lodash/assign";

import BoCard from '@/components/BoCard.vue'
import BoCardImg from '@/components/BoCardImg.vue'

export default {
  extends: GlobalVue,

  components: {
    PageTitle,
    BoCard,
    BoCardImg,
    ckeditor: CKEditor.component,
  },

  data() {
    return {
      // GENERAL
      lang: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Indonesian",
          value: "id",
        }
      ],
      editor: ClassicEditor,
      editorConfig: {
        fontColor: {
          colors: [
            {
              color: "hsl(221, 99%, 43%)",
              label: "Blue",
            },
            {
              color: "hsl(0, 83%, 47%)",
              label: "Red",
            },
            {
              color: "hsl(93, 69%, 49%)",
              label: "Green",
            },
            {
              color: "hsl(322, 100%, 62%)",
              label: "Pink",
            },
            {
              color: "hsl(0, 0%, 0%)",
              label: "Black",
            },
            {
              color: "hsl(0, 0%, 30%)",
              label: "Dim grey",
            },
            {
              color: "hsl(0, 0%, 60%)",
              label: "Grey",
            },
            {
              color: "hsl(0, 0%, 90%)",
              label: "Light grey",
            },
            {
              color: "hsl(0, 0%, 100%)",
              label: "White",
              hasBorder: true,
            },
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        ui: {
          viewportOffset: {
            top: 65,
          },
        },
        toolbar: {
          items: ["heading", "|", "bold", "fontColor", "link"],
        },
      },

      // ROW
      content: {},

      editFormId: "",

      // INPUT
      inputHeroImage: {}
    };
  },

  created() {
    this.apiGet();
  },

  methods: {
    // General

    updateContent(type, input, IDFORM, modalId = false, usingKey = false) {
      this.doSubmit(
        "/do/" + this.modulePage,
        assign(
          {
            type,
          },
          {},
          {
            as_value: input,
          }
        ),
        (type) => {
          if (type === "success") {
            this.apiGet();
            this.editFormId = "";
            if (modalId) {
              this.$bvModal.hide(modalId);
            }
          }
        },
        "POST",
        IDFORM,
        usingKey
      );
    },

    // Hero Image
    editHeroImage(data) {
      this.inputHeroImage = {
        ...data
      }

      this.$bvModal.show('heroImageModal')
    },

    // Schedule
    addNewSchedule(data) {
      let inputData = {
        title: '',
        description: '',
        value: ''
      }

      data.push(inputData)
    },

    // Item Benefit
    addNewItemBenefit(data) {
      let inputData = {
        text: '',
        img: '',
        fallback_img: '',
        alt_img: ''
      }

      data.push(inputData)
    },

    // Collection Requirement
    addNewCollectionRequirement(data) {
      let inputData = {
        title: '',
        description: '',
      }

      data.push(inputData)
    },

    deleteItemData(data, index) {
      if(index > -1) {
        data.splice(index, 1);
      }
    }
  },
  mounted() {
    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10;
        } catch (error) {
          console.log(error);
        }
      },
    });
  },
}
  
</script>